const SET_BRANDS = 'SET_BRANDS';
const SET_BRAND = 'SET_BRAND';
const SET_SEARCH_BRANDS = 'SET_SEARCH_BRANDS';
const SET_BRAND_CATEGORIES = 'SET_BRAND_CATEGORIES';
const SELECTED_CATEGORY = 'SELECTED_CATEGORY';

const brandsState = {
	brands: [],
	brandsForSearch: [],
	brand: '',
	categories: [],
};

const brandsReducer = (state = brandsState, action) => {
	const type = action.type;
	switch (type) {
		case SET_BRANDS: {
			const brands = action.payload;
			return {
				...state,
				brands,
				brandsForSearch: brands,
			};
		}
		case SET_BRAND: {
			const brand = action.payload;
			return {
				...state,
				brand,
			};
		}
		case SET_SEARCH_BRANDS: {
			const brands = action.payload;
			if (!brands) {
				const savedBrands = [...state.brandsForSearch];
				return {
					...state,
					brands: savedBrands,
				};
			}
			const filteredCategories = [...state.brandsForSearch].filter((brand) =>
				brand.name.toString().toLowerCase().includes(brands.toLowerCase())
			);
			return {
				...state,
				brands: filteredCategories,
			};
		}
		case SET_BRAND_CATEGORIES: {
			const categories = [];
			for (let category of action.payload) {
				categories.push({
					category,
					selected: false,
				});
			}
			return {
				...state,
				categories,
			};
		}
		case SELECTED_CATEGORY: {
			const id = action.payload;
			const categories = [...state.categories];
			const index = categories.findIndex((category) => category.category.id === id);
			const oldStatus = categories[index].selected;
			categories[index].selected = !oldStatus;
			return {
				...state,
				categories,
			};
		}
		default: {
			throw new Error(`${type} is not defined in brands reducer`);
		}
	}
};

export {
	SET_BRANDS,
	SET_BRAND,
	SET_SEARCH_BRANDS,
	SET_BRAND_CATEGORIES,
	SELECTED_CATEGORY,
	brandsState,
	brandsReducer,
};
