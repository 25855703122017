import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const PARA_E_COMMERCE_USER = 'PARA_E_COMMERCE_USER';
const PARA_E_COMMERCE_TOKEN = 'PARA_E_COMMERCE_TOKEN';
const PARA_E_COMMERCE_CART = 'PARA_E_COMMERCE_CART';

export const setUser = (user) => {
	localStorage.setItem(PARA_E_COMMERCE_USER, JSON.stringify(user));
};

export const setToken = (token) => {
	localStorage.setItem(PARA_E_COMMERCE_TOKEN, token);
};

export const getUser = () => {
	const user = localStorage.getItem(PARA_E_COMMERCE_USER);
	if (user) return JSON.parse(user);
	return null;
};

export const getToken = () => {
	const token = localStorage.getItem(PARA_E_COMMERCE_TOKEN);
	if (token) return token;
	return null;
};

export const removeUser = () => {
	localStorage.clear();
};

export const addCart = (cart) => {
	localStorage.setItem(PARA_E_COMMERCE_CART, JSON.stringify(cart));
};

export const getCart = () => {
	const cart = localStorage.getItem(PARA_E_COMMERCE_CART);
	return JSON.parse(cart);
};

export const removeCart = () => {
	localStorage.removeItem(PARA_E_COMMERCE_CART);
};

export const calSubTotal = (products) => {
	let price = 0;
	for (let product of products) {
		price += +product.product.price * +product.count;
	}
	return formatFloatNumber(price);
};

export const calCount = (products) => {
	return products?.length;
};

export const confirmDialog = (title, content, callback) => {
	confirmAlert({
		title: title,
		message: content,
		buttons: [
			{
				label: 'Confirm',
				onClick: () => {
					callback(true);
				},
			},
			{
				label: 'No',
				onClick: () => {
					callback(false);
				},
			},
		],
	});
};

export const formatFloatNumber = (number) => {
	return +number.toFixed(3).toString().substr(0, 5);
};

export const checkInCart = (cartProducts, productId) => {
	const isInCart = cartProducts?.find((product) => product.product.id === productId);
	return isInCart?.count && isInCart?.count;
};
