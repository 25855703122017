export default function CloseDropDown(ref, setDropDown) {
	//? Close drop down when click outside
	const handleClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			setDropDown(false);
		}
	};

	const effect = () => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	};
	//? End close drop down when click outside

	return {
		effect,
	};
}
