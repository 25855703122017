export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_SEARCH_CATEGORIES = 'SET_SEARCH_CATEGORIES';

export const categoriesState = {
	categories: [],
	categoriesForSearch: [],
};

export const categoriesReducer = (state = categoriesState, action) => {
	if (action.type === SET_CATEGORIES) {
		const categories = action.payload;
		return {
			categories,
			categoriesForSearch: categories,
		};
	}
	if (action.type === SET_SEARCH_CATEGORIES) {
		const categories = action.payload;
		if (!categories) {
			const savedCategories = [...state.categoriesForSearch];
			return {
				...state,
				categories: savedCategories,
			};
		}
		const filteredCategories = [...state.categoriesForSearch].filter((category) =>
			category.name.toString().toLowerCase().includes(categories.toLowerCase())
		);
		return {
			...state,
			categories: filteredCategories,
		};
	} else {
		throw new Error(`${action.type} is not defined in categories reducer`);
	}
};
