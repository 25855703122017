import React, { useContext, useMemo, useState, useEffect, useRef } from 'react';
import Brands from './Brands';
import IconButton from '../../buttons/IconButton';
import { TabIndexContext, SelectedCategoryContext } from '../../../app/context';
import useCloseDialog from '../../../app/hooks/CloseDropDown';

export default function FilterByBrands() {
	const { currentIndex } = useContext(TabIndexContext);
	const { brands } = useContext(SelectedCategoryContext);
	const [brandsDropdown, setBrandsDropdown] = useState(false);
	const ref = useRef(null);

	const selectedBrandsCount = useMemo(() => {
		let count = 0;
		for (let brand of brands) {
			if (brand.selected) count += 1;
		}
		return count;
	}, [brands]);

	const toggleBrandsDropDown = () => {
		setBrandsDropdown((prevState) => !prevState);
	};

	const { effect } = useCloseDialog(ref, setBrandsDropdown);

	useEffect(() => {
		effect();
		// eslint-disable-next-line
	}, []);

	return (
		<div className="relative sm:ml-10">
			{currentIndex === 0 && brands.length > 0 && (
				<>
					<div className="w-36">
						<IconButton
							icon={<i className="bi bi-filter text-xl mr-2 mt-0.5"></i>}
							title="Brands"
							handleClick={toggleBrandsDropDown}
							numberOfBrands={selectedBrandsCount > 0 ? selectedBrandsCount : null}
						/>
					</div>
					{brandsDropdown && (
						<div ref={ref}>
							<Brands setBrandsDropdown={setBrandsDropdown} />
						</div>
					)}
				</>
			)}
		</div>
	);
}
