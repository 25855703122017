import React, { useRef, useState, useEffect } from 'react';
import MobileNavbar from '../../dropdown/MobileNavbar';
import useCloseDropDown from '../../../app/hooks/CloseDropDown.js';
import Bounce from 'react-reveal/Bounce';
import TopSearch from '../../TopBoxSearch/TopSerach';
import './NavbarMobile.css';

export default function NavbarMobile() {
	const ref = useRef(null);
	const [state, setState] = useState({
		open: false,
	});

	const toggleNavbar = () => {
		setState((prevState) => ({
			...prevState,
			open: !prevState.open,
		}));
	};
	const { effect } = useCloseDropDown(ref, toggleNavbar);

	useEffect(() => {
		effect();
		// eslint-disable-next-line
	}, []);

	const { open } = state;
	return (
		<div className="MobileHeaderWrapper MobileHeader">
			<div className="MobileHeaderInnerWrapper MobileHeaderInner sticky home desktop">
				<i className="bi bi-list text-2xl cursor-pointer" onClick={toggleNavbar}></i>
				{open ? (
					<div className="relative" ref={ref}>
						<Bounce>
							<MobileNavbar setOpen={toggleNavbar} />
						</Bounce>
					</div>
				) : null}
				<div className="LogoWrapper Logo">
					<span className="logo-LogoBox LogoBox">
						<img src="/favicon.png" alt="shop logo" className="logo-LogoImage LogoImage" />
					</span>
				</div>
				<div className="hidden md:inline md:w-80">
					<TopSearch />
				</div>
			</div>
		</div>
	);
}
