require('dotenv').config();
const root = process.env.REACT_APP_ROOT;
// const root = 'http://oil.sanbot-zara.com/api';

const file = `${root}/storage/`;
const baseURL = `${root}/api`;

const USER = `${baseURL}/user`;
const LOGIN = `${USER}/login`;
const CART = `${USER}/cart`;
const ORDER = `${USER}/order`;
const UPDATE = `${USER}/update`;
const FORGET_PASSWORD = `${USER}/forget-password`;
const ADDRESSES = `${USER}/address`;

const CATEGORIES = `${baseURL}/categories`;

const HOME_SLIDERS = `${baseURL}/homeslider`;

const PRODUCT = `${baseURL}/products`;
const PRODUCTS = `${PRODUCT}`;

const BRANDS = `${baseURL}/brands`;

const PROMOTIONS = `${baseURL}/promotions`;

const COUNTRIES = `${baseURL}/countries`;
const CITIES = `${baseURL}/cities`;

const BRANDS_BY_CATEGORY = `${BRANDS}/by-categories`;
const CATEGORIES_BY_BRANDS = `${CATEGORIES}/by-brands`;

export {
	file,
	USER,
	LOGIN,
	CART,
	ORDER,
	UPDATE,
	CATEGORIES,
	PRODUCTS,
	FORGET_PASSWORD,
	HOME_SLIDERS,
	BRANDS,
	PROMOTIONS,
	COUNTRIES,
	CITIES,
	ADDRESSES,
	BRANDS_BY_CATEGORY,
	CATEGORIES_BY_BRANDS,
};
