import React, { useContext, useState } from 'react';
import {
	ProductsContext,
	ProgressBarContext,
	UserContext,
	PaginationContext,
	SelectedCategoryContext,
	BrandsContext,
	TabIndexContext,
} from '../../app/context';
import { getProducts } from '../../app/server/services';
import { SET_PROGRESS } from '../../app/reducers/progressbar-reducer';
import { SET_PRODUCTS } from '../../app/reducers/products-reducer';
import './TopSearch.css';

export default function TopSearch() {
	const { dispatch } = useContext(ProductsContext);
	const { category } = useContext(SelectedCategoryContext);
	const { token } = useContext(UserContext);
	const progressBarDispatch = useContext(ProgressBarContext).dispatch;
	const pageDispatch = useContext(PaginationContext).dispatch;
	const { brand } = useContext(BrandsContext);
	const { currentIndex } = useContext(TabIndexContext);
	const [search, setSearch] = useState('');

	const handleSearchClick = async (e) => {
		e.preventDefault();
		if (search) {
			progressBarDispatch({ type: SET_PROGRESS });
			const res = await getProducts(
				currentIndex === 0 ? category?.id : '', //! check if current tab is categories or brands
				1,
				token,
				search,
				currentIndex === 1 ? brand?.id : '' //! check if current tab is categories or brands
			);
			progressBarDispatch({ type: SET_PROGRESS });
			dispatch({
				type: SET_PRODUCTS,
				payload: {
					products: res.data,
					pages: res.total_pages,
				},
			});
			pageDispatch(1);
		}
	};

	const handleChange = (e) => {
		setSearch(e.target.value);
	};

	return (
		<div>
			<form className="search" onSubmit={handleSearchClick}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="15px"
					height="15px"
					viewBox="0 0 17.048 18"
					className="cursor-pointer"
					onClick={handleSearchClick}
					style={{ color: 'rgb(199, 199, 199)' }}
				>
					<path
						id="Path_142"
						data-name="Path 142"
						d="M380.321,383.992l3.225,3.218c.167.167.341.329.5.506a.894.894,0,1,1-1.286,1.238c-1.087-1.067-2.179-2.131-3.227-3.236a.924.924,0,0,0-1.325-.222,7.509,7.509,0,1,1-3.3-14.207,7.532,7.532,0,0,1,6,11.936C380.736,383.462,380.552,383.685,380.321,383.992Zm-5.537.521a5.707,5.707,0,1,0-5.675-5.72A5.675,5.675,0,0,0,374.784,384.513Z"
						transform="translate(-367.297 -371.285)"
						fill="currentColor"
					></path>
				</svg>
				<input
					name="search"
					placeholder="Search your products"
					className="SearchInput"
					value={search}
					onChange={handleChange}
				/>
			</form>
		</div>
	);
}
