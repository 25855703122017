const paginationState = {
	page: 1,
};

const paginationReducer = (state = paginationState, action) => {
	return {
		page: action,
	};
};

export { paginationReducer, paginationState };
