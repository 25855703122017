import React from 'react';
import { Link } from 'react-router-dom';
import useLogout from '../../app/hooks/Logout';
import '../layout/Navbar/Navbar.css';

const links = [
	{
		name: 'Home',
		icon: <i class="bi bi-house-door-fill text-2xl"></i>,
		to: '/',
	},
	{
		name: 'Offers',
		icon: <i className="bi bi-tag-fill text-2xl"></i>,
		to: '/offers',
	},
	{
		name: 'Profile',
		icon: <i className="bi bi-person-circle text-2xl"></i>,
		to: '/profile',
	},
];

const buttons = [
	{
		name: 'Logout',
		icon: <i className="bi bi-box-arrow-right text-2xl"></i>,
	},
];

function Item({ item }) {
	return (
		<div className="flex justify-start items-center cursor-pointer py-1 pl-3 rounded-sm hover:bg-green-700 hover:text-white transition_">
			<span className="mb-1">{item.icon}</span>
			<span className="w-32 my-2 ml-3 text-left text-sm">{item.name}</span>
		</div>
	);
}

export default function MobileNavbar({ setOpen }) {
	const { logoutHook } = useLogout();
	const handleClose = () => {
		setOpen(false);
	};

	const handleLogout = () => {
		logoutHook();
	};

	return (
		<div className="absolute top-6 -left-7">
			<div className="bg-white rounded-sm w-64 shadow-2xl h-75 flex flex-col border">
				<div className="flex flex-row justify-between items-center mt-5 mx-5 mb-3">
					<span className="logoStyle logo">
						<img src="/favicon.png" alt="Shop Logo" className="logoImage image" />
					</span>
					<span>
						<i className="bi bi-x-lg cursor-pointer" onClick={handleClose}></i>
					</span>
				</div>
				<div className="border mx-2 my-4"></div>
				<div>
					{links.map((link, index) => {
						return (
							<Link key={index} to={link.to} onClick={handleClose}>
								<Item item={link} />
							</Link>
						);
					})}
					{buttons.map((button, index) => {
						return (
							<div key={index} onClick={handleLogout} className="mb-5">
								<Item item={button} />
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}
