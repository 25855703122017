const SET_CATEGORY = 'SET_CATEGORY';
const SET_SUB_CATEGORIES = 'SET_SUB_CATEGORIES';
const SET_CATEGORY_BRANDS = 'SET_CATEGORY_BRANDS';
const SELECTED_BRAND = 'SELECTED_BRAND';

const selectedCategoryState = {
	category: null,
	subCategories: [],
	brands: [],
	categories: [],
};

const selectedCategoryReducer = (state = selectedCategoryState, action) => {
	const type = action.type;
	if (type === SET_CATEGORY) {
		return {
			...state,
			category: action.payload,
		};
	} else if (type === SET_SUB_CATEGORIES) {
		return {
			...state,
			subCategories: action.payload,
		};
	} else if (type === SET_CATEGORY_BRANDS) {
		const brands = [];
		for (let brand of action.payload) {
			brands.push({
				brand,
				selected: false,
			});
		}
		return {
			...state,
			brands,
		};
	} else if (type === SELECTED_BRAND) {
		const id = action.payload;
		const brands = [...state.brands];
		const index = brands.findIndex((brand) => brand.brand.id === id);
		const oldStatus = brands[index].selected;
		brands[index].selected = !oldStatus;
		return {
			...state,
			brands,
		};
	}
	return state;
};

export {
	SET_CATEGORY,
	SET_SUB_CATEGORIES,
	SET_CATEGORY_BRANDS,
	SELECTED_BRAND,
	selectedCategoryState,
	selectedCategoryReducer,
};
