import { useContext } from 'react';
import { UserContext } from '../context';
import { removeUser } from '../commonFunctions';
import { REMOVE_USER } from '../reducers/user-reducer';
import { confirmDialog } from '../commonFunctions';

export default function useLogout() {
	const { dispatch } = useContext(UserContext);
	const logoutHook = () => {
		confirmDialog('Logout Confirmation', 'Sure to logout?', (callback) => {
			if (callback) {
				removeUser();
				dispatch({ type: REMOVE_USER });
			}
		});
	};
	return { logoutHook };
}
