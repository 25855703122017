import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function CircleProgressBar({ height, width }) {
	return (
		<div>
			<SkeletonTheme color="#009E7F" highlightColor="#FFFFFF">
				<Skeleton circle={true} height={height} width={width} />
			</SkeletonTheme>
		</div>
	);
}
