import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../Navbar.css';

const links = [
	{
		title: 'Home',
		to: '/',
	},
	{
		title: 'Offers',
		to: '/offers',
	},
	{
		title: 'Profile',
		to: '/profile',
	},
];

const baseClass = 'px-5 pt-7 pb-6 text-base uppercase hover:text-green-600 transition duration-500 ease-in-out';

function CreateLinks() {
	const { pathname } = useLocation();
	return (
		<>
			{links.map((link) => {
				return (
					<NavLink
						key={link.to}
						exact
						to={link.to}
						title={link.title}
						className="font-medium flex items-center"
					>
						<span className={pathname === link.to ? `${baseClass} active-navbar link_` : baseClass}>
							{link.title}
						</span>
					</NavLink>
				);
			})}
		</>
	);
}

export default function RightNavbar() {
	return (
		<div className="w-52 h-20 flex items-center justify-center">
			<div className="flex justify-evenly">
				{/* <AppsIcon
					className="text-green-600 transition duration-700 ease-in-out cursor-pointer hover:text-green-800"
					onClick={handleMoreClick}
				/> */}
				{CreateLinks()}
				{/* {dropDown ? (
					<div ref={ref}>
						<Bounce>
							<DropDown setDropDown={setDropDown} />
						</Bounce>
					</div>
				) : null} */}
			</div>
		</div>
	);
}
