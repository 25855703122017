import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Categories from './Categories';
import IconButton from '../../buttons/IconButton';
import { TabIndexContext, BrandsContext } from '../../../app/context';
import useCloseDialog from '../../../app/hooks/CloseDropDown';

export default function FilterByCategories() {
	const { currentIndex } = useContext(TabIndexContext);
	const { categories } = useContext(BrandsContext);
	const [categoriesDropdown, setCategoriesDropdown] = useState(false);
	const ref = useRef(null);

	const selectedCategoriesCount = useMemo(() => {
		let count = 0;
		for (let category of categories) {
			if (category.selected) count += 1;
		}
		return count;
	}, [categories]);

	const toggleCategoriesDropDown = () => {
		setCategoriesDropdown((prevState) => !prevState);
	};

	const { effect } = useCloseDialog(ref, setCategoriesDropdown);

	useEffect(() => {
		effect();
		// eslint-disable-next-line
	}, []);

	return (
		<div className="relative sm:ml-10">
			{currentIndex === 1 && categories.length > 0 && (
				<div ref={ref}>
					<div className="w-44">
						<IconButton
							icon={<i className="bi bi-filter text-xl mr-2 mt-0.5"></i>}
							title="Categories"
							handleClick={toggleCategoriesDropDown}
							numberOfBrands={selectedCategoriesCount > 0 ? selectedCategoriesCount : null}
						/>
					</div>
					{categoriesDropdown ? (
						<div ref={ref}>
							<Categories setCategoriesDropdown={setCategoriesDropdown} />
						</div>
					) : null}
				</div>
			)}
		</div>
	);
}
