const SET_PROGRESS = 'SET_PROGRESS';

const progressState = {
	isShown: false,
};

const progressReducer = (state = progressState, action) => {
	const type = action.type;
	switch (type) {
		case SET_PROGRESS: {
			return {
				isShown: !state.isShown,
			};
		}
		default: {
			return state;
		}
	}
};

export { SET_PROGRESS, progressReducer, progressState };
