const SET_PRODUCTS = 'SET_PRODUCTS';

const productsState = {
	products: [],
	pages: 0,
};

const productsReducer = (state = productsState, action) => {
	const type = action.type;
	const { products, pages } = action.payload;
	switch (type) {
		case SET_PRODUCTS: {
			return {
				products,
				pages,
			};
		}
		default: {
			throw new Error(`${type} is not defined in products reducer`);
		}
	}
};

export { SET_PRODUCTS, productsReducer, productsState };
