import { useContext, useEffect, useReducer, Suspense, lazy } from 'react';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import './App.css';
import { HashRouter, Switch, Route, useHistory, Redirect } from 'react-router-dom';
import Navbar from './components/layout/Navbar/Navbar';
import {
	CartContext,
	CategoriesContext,
	SelectedCategoryContext,
	UserContext,
	ProductsContext,
	ProgressBarContext,
	PaginationContext,
	BrandsContext,
	TabIndexContext,
} from './app/context';
import { cartReducer, cartState, SET_CART } from './app/reducers/cart-reducer';
import { categoriesReducer, categoriesState } from './app/reducers/categories-reducer';
import { selectedCategoryReducer, selectedCategoryState } from './app/reducers/selected-category';
import { SET_USER, userReducer, userState } from './app/reducers/user-reducer';
import { productsReducer, productsState } from './app/reducers/products-reducer';
import { progressReducer, progressState } from './app/reducers/progressbar-reducer';
import { paginationReducer, paginationState } from './app/reducers/pagination-reducer';
import { brandsReducer, brandsState } from './app/reducers/brands-reducer';
import { tabIndexReducer, tabIndexState } from './app/reducers/tab-index-reducer';
import { getCart, getToken, getUser } from './app/commonFunctions';
import { ToastProvider } from 'react-toast-notifications';
import { getCartProducts } from './app/server/services';
import CircleProgressBar from './components/progress-bar/CircleProgressBar';

const Home = lazy(() => import('./Pages/Home/Home'));
const Profile = lazy(() => import('./Pages/Profile/Profile'));
const Login = lazy(() => import('./Pages/Login/Login'));
const ResetPassword = lazy(() => import('./Pages/ResetPassword/ResetPassword'));
const NotFound = lazy(() => import('./components/errors/PageNotFound'));
const ErrorHandler = lazy(() => import('./components/errors/ErrorHandler'));
const CheckoutPage = lazy(() => import('./Pages/Checkout/CheckoutPage'));
const OffersPage = lazy(() => import('./Pages/Offers/Offers'));

function Routing() {
	const { dispatch, token } = useContext(UserContext);
	const { dispatch: cartDispatch } = useContext(CartContext);

	const history = useHistory();

	useEffect(() => {
		const checkUser = async () => {
			const savedToken = getToken();
			if (savedToken) {
				const savedUser = getUser();
				const savedCart = getCart();
				//* Check if local storage has a cart
				const products = savedCart?.length > 0 ? savedCart : await getCartProducts(savedToken);
				let formingProducts = [];
				//* This will be execute when local storage hasn't cart and online cart has products (when user open his account from another device)
				if (products[0]?.id) {
					for (let product of products) {
						formingProducts.push({
							count: product?.quantity,
							product: product?.product,
						});
					}
					cartDispatch({ type: SET_CART, payload: formingProducts });
				} else {
					cartDispatch({ type: SET_CART, payload: products });
				}
				dispatch({
					type: SET_USER,
					payload: {
						token: savedToken,
						user: savedUser,
					},
				});
				return;
			}
			history.push('/login');
		};
		checkUser();
		// eslint-disable-next-line
	}, [token]);
	return (
		<>
			{getToken() || token ? (
				<Switch>
					<Route
						exact
						path="/"
						render={(props) => (
							<ErrorHandler {...props}>
								<Home {...props} />
							</ErrorHandler>
						)}
					/>
					<Route
						path="/profile"
						render={(props) => (
							<ErrorHandler {...props}>
								<Profile {...props} />
							</ErrorHandler>
						)}
					/>
					<Route
						path="/checkout"
						render={(props) => (
							<ErrorHandler {...props}>
								<CheckoutPage {...props} />
							</ErrorHandler>
						)}
					/>
					<Route
						path="/offers"
						render={(props) => (
							<ErrorHandler {...props}>
								<OffersPage {...props} />
							</ErrorHandler>
						)}
					/>
					<Route component={NotFound} />
				</Switch>
			) : (
				<Switch>
					<Route path="/login" component={Login} />
					<Route path="/reset-password" component={ResetPassword} />
					<Redirect to="/login" />
				</Switch>
			)}
		</>
	);
}

function CartContextFunction() {
	const [cartState_, cartDispatch] = useReducer(cartReducer, cartState);

	return (
		<CartContext.Provider
			value={{
				dispatch: cartDispatch,
				products: cartState_.products,
			}}
		>
			<ToastProvider autoDismiss autoDismissTimeout={4000} placement="bottom-center">
				<HashRouter>
					<Navbar />
					<ErrorHandler>
						<Routing />
					</ErrorHandler>
				</HashRouter>
			</ToastProvider>
		</CartContext.Provider>
	);
}

function CategoriesFunction() {
	const [categories, categoriesDispatch] = useReducer(categoriesReducer, categoriesState);
	return (
		<CategoriesContext.Provider
			value={{
				categories: categories.categories,
				categoriesForSearch: categories.categoriesForSearch,
				dispatch: categoriesDispatch,
			}}
		>
			<CartContextFunction />
		</CategoriesContext.Provider>
	);
}

function SelectedCategoryFunction() {
	const [state, dispatch] = useReducer(selectedCategoryReducer, selectedCategoryState);
	return (
		<SelectedCategoryContext.Provider
			value={{
				category: state.category,
				subCategories: state.subCategories,
				brands: state.brands,
				dispatch,
			}}
		>
			<CategoriesFunction />
		</SelectedCategoryContext.Provider>
	);
}

function UserContextFunction() {
	const [user, userDispatch] = useReducer(userReducer, userState);
	return (
		<UserContext.Provider
			value={{
				token: user.token,
				user: user.user,
				dispatch: userDispatch,
			}}
		>
			<SelectedCategoryFunction />
		</UserContext.Provider>
	);
}

function ProductsContextFunction() {
	const [products, productsDispatch] = useReducer(productsReducer, productsState);
	return (
		<ProductsContext.Provider
			value={{
				dispatch: productsDispatch,
				products: products.products,
				pages: products.pages,
			}}
		>
			<UserContextFunction />
		</ProductsContext.Provider>
	);
}

function ProgressBarContextFunction() {
	const [progressBar, progressBarDispatch] = useReducer(progressReducer, progressState);
	return (
		<ProgressBarContext.Provider
			value={{
				dispatch: progressBarDispatch,
				isShown: progressBar.isShown,
			}}
		>
			<ProductsContextFunction />
		</ProgressBarContext.Provider>
	);
}

function PaginationContextFunction() {
	const [page, pageDispatch] = useReducer(paginationReducer, paginationState);
	return (
		<PaginationContext.Provider
			value={{
				dispatch: pageDispatch,
				page: page.page,
			}}
		>
			<ProgressBarContextFunction />
		</PaginationContext.Provider>
	);
}

function BrandsContextFunction() {
	const [state, dispatch] = useReducer(brandsReducer, brandsState);
	return (
		<BrandsContext.Provider
			value={{
				dispatch,
				brands: state.brands,
				brandsForSearch: state.brandsForSearch,
				brand: state.brand,
				categories: state.categories,
			}}
		>
			<PaginationContextFunction />
		</BrandsContext.Provider>
	);
}

function TabIndexContextFunction() {
	const [state, dispatch] = useReducer(tabIndexReducer, tabIndexState);
	return (
		<TabIndexContext.Provider
			value={{
				dispatch,
				currentIndex: state.currentIndex,
			}}
		>
			<BrandsContextFunction />
		</TabIndexContext.Provider>
	);
}

function App() {
	return (
		<div className="App">
			<Suspense
				fallback={
					<div className="h-screen w-screen flex items-center justify-center">
						<CircleProgressBar width={60} height={60} />
					</div>
				}
			>
				<TabIndexContextFunction />
			</Suspense>
		</div>
	);
}

export default App;
