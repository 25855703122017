import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import RightNavbar from './RightItemNavbar/RightNavbar';
import LeftNavbar from './LeftItemNavbar/LeftNavbar';
import NavbarMobile from '../NavbarMobile/NavbarMobile';
import { UserContext } from '../../../app/context';
import { getToken } from '../../../app/commonFunctions';
import './Navbar.css';

export default function Navbar() {
	const { token } = useContext(UserContext);

	let navbarClasses = 'sticky-outer-wrapper shadow-md';
	let styleClass;
	let isSticky_mobile;
	navbarClasses += ' active';
	styleClass = {
		position: 'fixed',
		zIndex: 1001,
		top: '0px',
		width: '1349px',
	};
	isSticky_mobile = 'MobileHeaderInnerWrapper MobileHeaderInner sticky home desktop';


	return (
		<>
			{getToken() || token ? (
				<nav>
					<div className={navbarClasses}>
						<div className="sticky-inner-wrapper" style={styleClass}>
							<div className="MobileHeaderWrapper MobileHeader">
								<div className={isSticky_mobile}>
									<NavbarMobile />
								</div>
							</div>
							<header className="header-style top-navbar Sticky shadow" id="layout-header">
								<LeftNavbar />
								<RightNavbar />
							</header>
						</div>
					</div>
				</nav>
			) : (
				<nav className="pt-4 px-10 flex justify-between items-center z-10 shadow-md">
					<span className="logoStyle logo mb-2">
						<img src="/favicon.png" alt="Shop Logo" className="logoImage image" />
					</span>
					<h3 className="font-bold text-2xl cursor-pointer hover:text-green-600 transition_ mb-4">
						<Link to="/login">Login</Link>
					</h3>
				</nav>
			)}
		</>
	);
}
