import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import FilterByBrands from '../../../dropdown/categories-by-brands/FilterByBrands';
import FilterByCategories from '../../../dropdown/brands-by-categories/FilterByCategories';
import TopSearch from '../../../TopBoxSearch/TopSerach';
import { TabIndexContext } from '../../../../app/context';
import '../Navbar.css';

export default function LeftNavbar() {
	const { pathname } = useLocation();
	const { currentIndex } = useContext(TabIndexContext);

	const renderFilterButton = () => {
		if (currentIndex === 0) return <FilterByBrands />;
		return <FilterByCategories />;
	};

	const isHome = pathname === '/' ? true : false;

	return (
		<div className="left-top-navbar left-items">
			<Link to="/">
				<span className="logoStyle logo">
					<img src="/favicon.png" alt="Shop Logo" className="logoImage image" />
				</span>
			</Link>
			<div>{isHome && renderFilterButton()}</div>
			<div className="md:ml-10 lg:w-80 xl:w-104">{isHome && <TopSearch />}</div>
		</div>
	);
}
