import React from 'react';

export default function IconButton({ handleClick, progressIsShown, title, icon, numberOfBrands }) {
	return (
		<button
			className="SearchButton hover:bg-green-600 rounded-3xl transition_"
			onClick={handleClick}
			disabled={progressIsShown ? true : false}
		>
			{icon}
			{title} {numberOfBrands && `(${numberOfBrands})`}
		</button>
	);
}
