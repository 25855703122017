const tabIndexState = {
	currentIndex: 0,
};

const tabIndexReducer = (state = tabIndexState, action) => {
	const { currentIndex } = { ...state };

	if (action === 0 && currentIndex === 0)
		return {
			currentIndex,
		};
	if (action === 0 && currentIndex === 1)
		return {
			currentIndex: 0,
		};
	if (action === 1 && currentIndex === 1)
		return {
			currentIndex,
		};
	if (action === 1 && currentIndex === 0)
		return {
			currentIndex: 1,
		};
};

export { tabIndexState, tabIndexReducer };
