import { addCart } from '../commonFunctions';

export const ADD_TO_CART = 'ADD_TO_CART';
export const SET_CART = 'SET_CART';
export const INCREASE_PRODUCT = 'INCREASE_PRODUCT';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const EMPTY_CART = 'EMPTY_CART';

export const cartState = {
	products: [],
};

const decreaseProduct = (state, index) => {
	const newCount = state.products[index].count - 1;
	const newProduct = {
		...state.products[index],
		count: newCount,
	};
	const products = [...state.products];
	products.splice(index, 1, newProduct);
	addCart(products);
	return {
		products,
	};
};

const removeProduct = (state, id) => {
	const newProducts = state.products.filter((product) => product.product.id !== id);
	addCart(newProducts);
	return {
		products: newProducts,
	};
};

export const cartReducer = (state = cartState, action) => {
	switch (action.type) {
		case SET_CART: {
			const products = action.payload;
			return {
				products,
			};
		}

		case EMPTY_CART: {
			return {
				products: [],
			};
		}

		case ADD_TO_CART: {
			try {
				const product = action.payload;
				let newProducts = [];
				//! when add product after delete, if wrong happened while deleting
				if (product.count) {
					newProducts = [
						...state.products,
						{
							product: product.product,
							count: product.count,
						},
					];
					//! when add to cart
				} else {
					newProducts = [
						...state.products,
						{
							product,
							count: 1,
						},
					];
				}
				addCart(newProducts);
				return {
					products: newProducts,
				};
			} catch (error) {
				alert('Something wrong happened!');
				break;
			}
		}

		case INCREASE_PRODUCT: {
			const id = action.payload;
			const index = state.products.findIndex((product) => product.product.id === id);
			if (index !== -1) {
				const newProduct = {
					...state.products[index],
					count: state.products[index].count + 1,
				};
				const products = [...state.products];
				products.splice(index, 1, newProduct);
				addCart(products);
				return { products };
			}
			return {
				products: state.products,
			};
		}

		case REMOVE_ITEM: {
			const id = action.payload;
			return removeProduct(state, id);
		}

		default: {
			try {
				const id = action.payload;
				const index = state.products.findIndex((product) => product.product.id === id);
				if (index !== -1) {
					const count = state.products[index]?.count;
					if (count === 1) {
						return removeProduct(state, id);
					}
					return decreaseProduct(state, index);
				}
			} catch (error) {
				alert('Something wrong happened!');
			}
		}
	}
};
