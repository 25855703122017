import axios from 'axios';
import {
	CATEGORIES,
	PRODUCTS,
	LOGIN,
	CART,
	ORDER,
	USER,
	UPDATE,
	FORGET_PASSWORD,
	HOME_SLIDERS,
	BRANDS,
	COUNTRIES,
	CITIES,
	ADDRESSES,
	BRANDS_BY_CATEGORY,
	CATEGORIES_BY_BRANDS,
	PROMOTIONS,
} from './APIs';

const getConfig = (token) => {
	return {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: `Bearer ${token}`,
		},
	};
};

const login = async (data) => {
	try {
		const res = await axios.post(LOGIN, data, getConfig(''));
		if (res.data.ok)
			return {
				token: res.data.token,
				user: res.data.user,
			};
		return {
			user: false,
			msg: res.data.error,
		};
	} catch (error) {
		console.log(error.response);
		return null;
	}
};

const getCategories = async () => {
	try {
		const res = await axios.get(CATEGORIES);
		return res.data;
	} catch (error) {
		console.log(error);
		return null;
	}
};

const getProducts = async (category = '', page, token, search = '', brand = '') => {
	try {
		console.info(`${PRODUCTS}?category=${category}&page=${page}&name=${search}&brand=${brand}`);
		const res = await axios.get(
			`${PRODUCTS}?category=${category}&page=${page}&name=${search}&brand=${brand}`,
			getConfig(token)
		);
		console.info(res.data)
		return res.data;
	} catch (error) {
		console.log(error.response);
		return null;
	}
};

const addToCart = async (productId, token) => {
	try {
		const data = {
			product_id: productId,
			quantity: 1,
		};
		const res = await axios.post(CART, data, getConfig(token));
		if (res.data.ok) {
			return 200;
		}
		return 400;
	} catch (error) {
		console.log(error.response);
		return null;
	}
};

const updateCart = async (productId, quantity, token) => {
	try {
		const res = await axios.put(
			`${CART}/${productId}`,
			{
				quantity,
			},
			getConfig(token)
		);
		if (res.data.ok) {
			return 200;
		}
		return 400;
	} catch (error) {
		console.log(error.response);
		return null;
	}
};

const removeFromCart = async (productId, token) => {
	try {
		const res = await axios.delete(`${CART}/${productId}`, getConfig(token));
		if (res.data.ok) return 200;
		return 400;
	} catch (error) {
		console.log(error);
		return null;
	}
};

const getCartProducts = async (token) => {
	try {
		const res = await axios.get(CART, getConfig(token));
		return res.data;
	} catch (error) {
		console.log(error.response);
		return null;
	}
};

const addOrder = async (data, token) => {
	try {
		const res = await axios.post(ORDER, data, getConfig(token));
		return res.data;
	} catch (error) {
		console.log(error.response);
		return null;
	}
};

const getOrders = async (token) => {
	try {
		const res = await axios.get(ORDER, getConfig(token));
		return res.data;
	} catch (error) {
		console.log(error.response);
		return null;
	}
};

const getUserInfo = async (token) => {
	try {
		const res = await axios.get(USER, getConfig(token));
		return res.data;
	} catch (error) {
		console.log(error.response);
		return null;
	}
};

const updateUserInfo = async (token, data) => {
	try {
		const res = await axios.post(UPDATE, data, getConfig(token));
		return res.data;
	} catch (error) {
		console.log(error.response);
		return null;
	}
};

const forgetPassword = async (token, email) => {
	try {
		const res = await axios.get(`${FORGET_PASSWORD}?email=${email}`, getConfig(token));
		if (res.data.ok) return 200;
		return 400;
	} catch (error) {
		console.log(error.response);
		return null;
	}
};

const getHomeSliders = async (token) => {
	try {
		const res = await axios.get(`${HOME_SLIDERS}`, getConfig(token));
		return res.data;
	} catch (error) {
		console.log(error.response);
		return null;
	}
};

const getBrands = async () => {
	try {
		const res = await axios.get(BRANDS);
		return res.data;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const getCountries = async (token) => {
	try {
		const res = await axios.get(COUNTRIES, getConfig(token));
		return res.data;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const getCities = async (token, countryId) => {
	try {
		const res = await axios.get(`${CITIES}/${countryId}`, getConfig(token));
		return res.data;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const addAddress = async (token, data) => {
	try {
		const res = await axios.post(ADDRESSES, data, getConfig(token));
		return res.data;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const editAddress = async (data, id, token) => {
	try {
		await axios.patch(`${ADDRESSES}/${id}`, data, getConfig(token));
		return true;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const getFullUserInfo = async (token) => {
	try {
		const [userInfo, addresses] = await Promise.all([
			axios.get(USER, getConfig(token)),
			axios.get(ADDRESSES, getConfig(token)),
		]);
		if (addresses.data.ok) {
			return {
				userInfo: userInfo.data,
				addresses: addresses.data.data,
			};
		}
		return null;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const getAddresses = async (token) => {
	try {
		const res = await axios.get(ADDRESSES, getConfig(token));
		return {
			deliveryFee: res.data.delivery_fee,
			addresses: res.data.data,
		};
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const removeAddress = async (addressId, token) => {
	try {
		const res = await axios.delete(`${ADDRESSES}/${addressId}`, getConfig(token));
		return res.data.ok;
	} catch (error) {
		console.info(error.response.data);
		return null;
	}
};

const getBrandsAndProducts = async (categories, token) => {
	try {
		const [products, brands] = await Promise.all([
			axios.get(`${PRODUCTS}?category=${categories}&page=${1}`, getConfig(token)),
			axios.get(`${BRANDS_BY_CATEGORY}?category=${categories}`),
		]);
		return [products.data, brands.data];
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const getCategoriesByBrands = async (brand, token) => {
	try {
		const [products, categories] = await Promise.all([
			axios.get(`${PRODUCTS}?brand=${brand}&page=${1}`, getConfig(token)),
			axios.get(`${CATEGORIES_BY_BRANDS}?brand=${brand}`),
		]);
		return [products.data, categories.data];
	} catch (error) {
		console.info(error.response.data);
		return null;
	}
};

const getPromotions = async (page, token) => {
	try {
		const res = await axios.get(`${PROMOTIONS}?page=${page}`, getConfig(token));
		return res.data;
	} catch (error) {
		console.info(error.response.data);
		return null;
	}
};

export {
	login,
	getCategories,
	getProducts,
	addToCart,
	updateCart,
	removeFromCart,
	getCartProducts,
	addOrder,
	getOrders,
	getUserInfo,
	updateUserInfo,
	forgetPassword,
	getHomeSliders,
	getBrands,
	getCountries,
	getCities,
	addAddress,
	getFullUserInfo,
	editAddress,
	getAddresses,
	removeAddress,
	getPromotions,
	getBrandsAndProducts,
	getCategoriesByBrands,
};
