import React from 'react';

const CartContext = React.createContext({
	products: [],
	dispatch: () => {},
});

const CategoriesContext = React.createContext({
	categories: [],
	categoriesForSearch: [],
	dispatch: () => {},
});

const BrandsContext = React.createContext({
	brands: [],
	brandsForSearch: [],
	brand: '',
	categories: [], //! for search categories by brands
	dispatch: () => {},
});

const SelectedCategoryContext = React.createContext({
	category: {},
	subCategories: [],
	brands: [], //! for search brands by categories;
	dispatch: () => {},
});

const UserContext = React.createContext({
	user: {},
	token: '',
	dispatch: () => {},
});

const ProductsContext = React.createContext({
	products: [],
	pages: 0,
	dispatch: () => {},
});

const ProgressBarContext = React.createContext({
	isShown: false,
	dispatch: () => {},
});

const PaginationContext = React.createContext({
	page: 1,
	dispatch: () => {},
});

const TabIndexContext = React.createContext({
	currentIndex: 0,
	dispatch: () => {},
});

export {
	CartContext,
	CategoriesContext,
	SelectedCategoryContext,
	UserContext,
	ProductsContext,
	ProgressBarContext,
	PaginationContext,
	BrandsContext,
	TabIndexContext,
};
