import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import { useToasts } from 'react-toast-notifications';
import IconButton from '../../buttons/IconButton';
import { ProgressBarContext, ProductsContext, UserContext, BrandsContext } from '../../../app/context';
import { SELECTED_CATEGORY } from '../../../app/reducers/brands-reducer';
import { SET_PROGRESS } from '../../../app/reducers/progressbar-reducer';
import { SET_PRODUCTS } from '../../../app/reducers/products-reducer';
import { getProducts } from '../../../app/server/services';

const searchIcon = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="17px"
		height="18px"
		viewBox="0 0 17.048 18"
		style={{ marginRight: 10 }}
	>
		<path
			id="Path_142"
			data-name="Path 142"
			d="M380.321,383.992l3.225,3.218c.167.167.341.329.5.506a.894.894,0,1,1-1.286,1.238c-1.087-1.067-2.179-2.131-3.227-3.236a.924.924,0,0,0-1.325-.222,7.509,7.509,0,1,1-3.3-14.207,7.532,7.532,0,0,1,6,11.936C380.736,383.462,380.552,383.685,380.321,383.992Zm-5.537.521a5.707,5.707,0,1,0-5.675-5.72A5.675,5.675,0,0,0,374.784,384.513Z"
			transform="translate(-367.297 -371.285)"
			fill="currentColor"
		></path>
	</svg>
);

export default function CategoriesComponent({ setCategoriesDropdown }) {
	const { addToast } = useToasts();

	const { categories, dispatch, brand } = useContext(BrandsContext);
	const { token } = useContext(UserContext);
	const { dispatch: progressBarDispatch } = useContext(ProgressBarContext);
	const { dispatch: productsDispatch } = useContext(ProductsContext);
	const classes = 'flex items-center hover:text-green-500 cursor-pointer w-full';

	const handleCategory = (brand) => {
		dispatch({ type: SELECTED_CATEGORY, payload: brand });
	};

	const handleSearch = async () => {
		if (categories.length === 0) return;
		setCategoriesDropdown(false);

		let categories_ = '';
		for (let category of categories) {
			if (category.selected) {
				categories_ += `${category.category.id}-`;
			}
		}

		categories_ = categories_.substr(0, categories_.lastIndexOf('-'));

		progressBarDispatch({ type: SET_PROGRESS });
		const res = await getProducts(categories_, 1, token, '', brand.id);
		progressBarDispatch({ type: SET_PROGRESS });
		if (res) {
			productsDispatch({
				type: SET_PRODUCTS,
				payload: {
					products: res.data,
					pages: res.total_pages,
				},
			});
			return;
		}
		addToast('Something wrong happened!', { appearance: 'error' });
	};

	return (
		<div>
			<Fade duration={500}>
				<div className="absolute w-86 right-0 top-12 md:-left-4 bg-white z-50 rounded-3xl p-2 shadow-md border-2">
					<ul className="grid grid-cols-2 gap-4 text-gray-400 pt-1 pb-4">
						{categories.map((category) => {
							return (
								<li key={category.category.id} className={classes}>
									<input
										readOnly
										id={`category-${category.category.id}`}
										type="checkbox"
										checked={category.selected}
										onClick={() => {
											handleCategory(category.category.id);
										}}
									/>
									<label
										htmlFor={`category-${category.category.id}`}
										className="pl-2 pb-1 mt-4 w-36 text-gray-500"
									>
										{category?.category?.name}
									</label>
								</li>
							);
						})}
					</ul>
					<div className="mt-5 flex justify-center">
						<IconButton title="Search" handleClick={handleSearch} icon={searchIcon} />
					</div>
				</div>
			</Fade>
		</div>
	);
}
